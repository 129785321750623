.fill__table {
    width: 100%;
    padding: 0 20px;

    .table__head {
        width: 100%;
        display: flex;
    }

    .table__content {
        .table__line {
            display: flex;
        }
    }

    .line__content {
        padding: 5px 10px;
        border: 1px solid #ddddddcc;
        border-top: none;
        border-left: none;


        &:last-child {
            border-right: none;


            .button__box {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .h-icon {
                    cursor: pointer;
                }
            }

        }

        .line__text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .isOpen {
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon-down {
            width: 14px;
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            margin-right: 5px;
        }
    }

    .is__child2 {
        padding-left: 30px;
    }

    .is__child3 {
        padding-left: 42px;
    }

    .is__child4 {
        padding-left: 62px;
    }

}