@import "../../color";

$h-info-line-height: 20px;
.h-info-line {
  position: relative;
  padding-left: 80px;
  width: 100%;
  margin-bottom: 8px;
  height: $h-info-line-height;
  line-height: $h-info-line-height;
  text-align: right;
  color: $h-info-line-value-color;
  box-shadow: 0 1px 2px 0 $h-info-line-shadow-color;
  border-radius: 6px 4px 4px 6px;
  cursor: default;

  &-title {
    position: absolute;
    left: 0;
    padding-left: 4px;
    display: inline-flex;
    align-items: center;
    width: 80px;
    height: inherit;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    color: $h-info-line-title-color;
    background: $h-info-line-title-bg;
    border-radius: 4px;
    z-index: 2;

    .ant-input {
      color: $h-info-line-title-color
    }
  }

  &-content {
    position: absolute;
    right: 0;
    left: 77px;
    //padding-right: 4px;
    width: calc(100% - 76px);
    height: $h-info-line-height;
    color: $h-info-line-value-color;
    font-size: $font-s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.h-white {
      color: $white;
    }
  }

  &-click {
    position: absolute;
    padding-right: 10px;
    background: $h-info-line-click-bg;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    transition: 0.5s;
    z-index: 0;

    &:after {
      position: absolute;
      right: 5px;
      top: 5px;
      content: "";
      height: 10px;
      line-height: 10px;
      color: $h-info-line-flag-color;
      border-right: 1px solid;
    }

    &-area {
      position: absolute;
      left: 0;
      width: 100%;
      height: $h-info-line-height;
    }

  }

  &-default {
    padding: 0 4px;
    //position: absolute;
    //width: 100%;
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
  }

  &-value {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    padding: 0 4px;
    height: $h-info-line-height;
    color: $h-info-line-value-color;
    background: $h-info-line-click-bg;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 0.5s;

    .ant-input {
      color: $h-info-line-value-color;
    }
  }

  &-suffix {
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    .h-icon + .h-icon {
      margin-left: 5px;
    }
  }

  .anticon {
    font-size: $font-l;
  }

  &-img {
    position: absolute;
    top: 24px;
    left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 8px);
    height: 120px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  //&-right-icon{
  //  position: absolute;
  //  right: 4px;
  //  top: 4px;
  //  background: #FFFFFF;
  //}
}