@import "../../color.scss";

.mini-login-box {
  width: 100%;
  height: 313px;
  margin-bottom: 16px;
  border: 1px solid rgba(151, 151, 151, 1);
}
.system-right-content {
  width: 100%;
  height: 473px;
  background: $white;
  box-shadow: 0px 2px 8px 0px $black10;
}
