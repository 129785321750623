@import "../../color.scss";
//.h-select {
//  $input-height: 18px;
//
//  &-content {
//    position: relative;
//    width: 212px;
//    //height: 16px;
//
//  }
//
//  &-input {
//    width: 212px;
//    height: $h-input-height;
//    color: $h-input-text-color;
//    border-radius: 2px;
//    border: 1px solid #dddddd;
//    outline: none;
//    cursor: pointer;
//    &::placeholder {
//      font-size: $font-s;
//      color: $black25;
//    }
//
//    &:focus-visible {
//      border-color: #f9f9f9;
//      box-shadow: 0 0 3px $highLight-color;
//    }
//  }
//
//  &-icon {
//    position: absolute;
//    right: 8px;
//    top: -1px;
//    color: rgba(0, 0, 0, 0.25); //todo
//    cursor: pointer;
//
//    .icon-close {
//      display: none;
//      width: 12px;
//      height: 12px;
//      background: #f5f5f5;
//      border-radius: 50%;
//      line-height: 12px;
//    }
//  }
//
//  //&-select {
//  //  width: 212px;
//  //  height: 16px;
//  //  border-radius: 2px;
//  //  border: 1px solid #DDDDDD;
//  //  outline: none;
//  //}
//
//
//  &-box {
//    margin-top: 2px;
//    width: 100%;
//    background: #fff;
//    border-radius: 2px;
//    border: 1px solid rgba(0, 0, 0, 0.15);
//  }
//
//  &-header {
//    display: flex;
//    align-items: center;
//    padding: 12px;
//    height: 39px;
//    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
//
//    &-title {
//      margin-left: 4px;
//      line-height: 16px;
//      font-size: 12px;
//      font-weight: 400;
//      color: rgba(0, 0, 0, 0.65);
//
//    }
//  }
//
//  &-input-box {
//    width: 212px;
//    height: $input-height;
//
//    &:hover {
//      .icon-close {
//        display: inline-block;
//      }
//    }
//  }
//
//  &-list {
//    height: 130px;
//    overflow-y: auto;
//
//    &-item {
//      display: flex;
//      align-items: center;
//      padding: 0 12px;
//      height: 32px;
//      line-height: 32px;
//      color: rgba(0, 0, 0, 0.65);
//      cursor: default;
//
//      .h-checkbox {
//        margin-right: 4px;
//      }
//
//      .text {
//        width: 100%;
//        overflow: hidden;
//        text-overflow: ellipsis;
//        white-space: nowrap;
//      }
//
//      &:hover {
//        background: #F0F7FD;
//      }
//    }
//  }
//}

.h-select-tag {
  background: $h-select-tag-bg;
  padding: 0 2px 0 4px;
  font-size: $font-s;
  height: 14px;
  line-height: 14px;
  cursor: default;

  .h-icon {
    font-size: $font-s;
    margin-left: 2px;
    cursor: pointer;
  }
}

.h-add-select {
  background: #fbfbfb;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: $black45;

  &.active {
    background: #eef8ed;
    color: $black45;
  }

  &-option {
    input {
      width: 100px;
    }

    .action-edit {
      display: none;
      margin-left: 8px;
    }

    &:hover {
      .action-edit {
        display: inline-block
      }
    }
  }
}