
#WebGL-output{
	width: 100%;
	height: 100%;
}
.amap-container .amap-maps .amap-layers .amap-markers .amap-marker .amap-icon{
  overflow:unset !important
}
.img-button{
  right:2%;
  position: absolute;
  z-index: 10;
}




/* .ant-btn-icon-only{
  position: absolute;
} */