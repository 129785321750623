
.h-water-solute{
  width: 285px;
  height: 48px;
  background-color: #ffffff;
  .h-solute-logo{
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 8px;
    background: rosybrown;
  }
  .h-solute-top{

    width: 285px;
    height: 28px;
    background: #F5F5F5;
    box-shadow: 0px 1px 2px 0px #DADADA;
    border-radius: 3px;

    padding-left: 56px;
    padding-right: 21px;

    span{

      font-size: 12px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: rgba(0, 0, 0, 0.65);
      line-height: 0.28px;
    }
    span:first-child{
      font-size: 16px;

      font-weight: 400;
      color: #35C64F;

    }
    span:last-child{
      margin: auto;
      float: right;
    }
  }
  .h-solute-bottom{
    width: 285px;
    height: 20px;
    background: #FBFBFB;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    padding-left:56px;
    padding-right: 21px;
    line-height: 20px;
    font-size: 12px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.65);


    .span-right{
      float: right;
    }
  }
}
.h-dropdown{
  width: 212px;
  height: auto;

  .h-dropdown-btn{
    width: 212px;
    height: 16px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #DDDDDD;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: rgba(29, 29, 29, 0.25);
    user-select: none;
    padding-left: 0.08px;
    .h-dropdown-ioc{

      width: 10px;
      height: 6px;
      margin-left:auto;
      margin-right: 1px;
      opacity: 1;
    }
  }

  .h-dropdown-num{
    width: 210px;
    height: auto;
    display: none;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    .h-dropdown-num-btn{
      width: 210px;
      height: 39px;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      display: flex;
      align-items: center;
      padding-left: 16px;
      img{
        width: 13px;
        height: 13px;

      }
      .h-drop-num-ioc{
        margin-left: 3px;
      }
      span{
        margin-left:4px;
        font-size: 12px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.65);
        line-height: 16px;
      }
    }
    .h-dropdown-num-text{
      width: 210px;
      height: auto;
      display: none;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      ul{
        width: 210px;
        height: 64px;
      }
    }
    .h-dropdown-num-ul{
      overflow-y: scroll;
      white-space:nowrap;
    }
    ul::-webkit-scrollbar{
      display:none;
    }
    ul{

      width: 210px;
      height: 128px;
      border: 1px solid #FFFFFF;
      list-style: none;
      padding: 0;
      margin: 0;
      li{

        width: 210px;
        height: 32px;
        padding-left: 16px;
        display: flex;
        align-items: center;
        img{
          width: 13px;
          height: 13px;

        }
        span{
          padding-left: 8px;
          font-size: 12px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: rgba(0, 0, 0, 0.65);
          line-height: 16px;
        }
      }
    }
  }

}