.share-data {
  & > div {
    margin-bottom: 8px;

    & > div:first-child {
      margin-bottom: 4px;
    }

    a {
      width: 100%;
      display: inline-block;
      word-wrap: break-word;
    }
  }

  &-password {
    font-size: 12px;
    font-weight: 400;
    color: #3b60a5;
  }
}

.h-plan-map {
  margin-bottom: 8px;
  width: 100%;
  height: 200px;
}
