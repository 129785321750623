@import "../../color.scss";

//图标
.h-icon {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  color: $h-icon-color;

  + .h-icon {
    margin-left: 12px;
  }
}

.h-clickable {
  cursor: pointer;
}

//头部导航菜单

.h-header-menu {
  @mixin active {
    padding-left: 6px;
    background: $h-header-menu-bg;
    border-left: 2px solid $h-header-menu-border-color;

    .h-icon, span {
      color: $h-header-menu-active-color;
    }
  }
  position: absolute;
  width: 76px;
  height: 12.8px;
  top: 20px;
  box-shadow: 0 0 7px 0px $h-header-box-shadow-color;
  z-index: 4;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 8px 8px;
    width: 100%;
    height: 32px;
    font-weight: 400;
    font-size: $font-s;
    background: $white;
    cursor: pointer;

    &:hover {
      @include active
    }
  }

  &-name {
    margin-left: 12px;
    color: #666666;
  }

  .menu-active {
    @include active
  }

}

//导航菜单

.h-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 888px;
  height: 21px;
  margin-bottom: 12px;

  //.ant-tabs {
  //  > .ant-tabs-nav {
  //    &::before {
  //      border: none;
  //    }
  //
  //    .ant-tabs-tab {
  //      font-size: $font-s;
  //      padding: 0 0 2px;
  //      border-bottom: 2px solid $highLight-color;
  //      z-index: 2;
  //    }
  //
  //    .ant-tabs-nav-wrap {
  //      align-self: center;
  //    }
  //  }
  //
  //}
  //
  //.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //  color: $highLight-color;
  //  text-shadow: none;
  //}

  .h-menu-list {
    display: block;
    width: calc(100% - 61px);
    height: 22px;

    & > div {
      display: inline-block;
      width: 60px;
      height: 21px;
      line-height: 21px;
      padding: 0 5px;
      margin-right: 20px;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      text-align: center;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    .h-menu-active {
      color: #3b60a5;
      border-bottom: 2px solid #3b60a5;
    }
  }

  .h-menu-suffix {
    display: flex;
    align-items: center;
  }
}

//右侧栏动画

@keyframes h-drawer-animation {
  0% {
    right: -300px;
  }
  100% {
    right: 0;
  }
}

//右侧栏
.h-drawer {
  position: fixed;
  top: 24px;
  width: 300px;
  height: calc(100vh - 24px);
  background: #fff;
  box-shadow: 0 2px 4px 0 $h-drawer-box-shadow-color;
  transition: 0.5s;
  overflow: hidden;
  z-index: 2;

  .h-drawer-title {
    width: 100%;
    height: 22px;
    padding: 0 8px 0 10px;
    background: #fff;
    box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);
    font-weight: 400;
    color: #333333;
    font-size: $font-s;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .h-icon {
      width: 14px;
      height: 14px;
      line-height: 14px;
      font-size: $font-m;
    }

    > .h-icon {
      cursor: pointer;
    }

    .h-drawer-title-left {
      span {
        margin-left: 8px;
        color: $h-drawer-title-color;
      }
    }
  }

  .h-drawer-content {
    height: calc(100% - 22px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 16px;
  }
}

.h-drawer-active {
  right: 0;
  animation-name: h-drawer-animation;
  animation-duration: 1s;
}

.h-drawer-unActive {
  right: -300px;
}

//搜索栏
.h-search {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  cursor: pointer;

  + .h-icon {
    margin-left: 12px;
  }

  .h-search-input {
    position: absolute;
    right: 22px;

    .h-input {
      height: 16px;
      font-size: $font-s;
      background: $h-search-input-bg;

      &.h-hide {
        width: 10px;
        z-index: -1;
      }

      &.h-show {
        width: 180px;
        z-index: 1;
        transition: 0.5s;
      }
    }
  }
}

//折叠面板

.h-collapse {
  .h-collapse-header {
    display: flex;
    align-items: center;
    height: 30px;
    background: #fcfcfc;
    padding: 8px;

    .h-collapse-header-left {
      display: flex;
      align-items: center;
      cursor: pointer;

      .h-icon {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        transition: 0.3s;
      }

      .icon-spin {
        transform: rotate(-90deg);
      }

      .h-collapse-header-title {
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        font-weight: 100;
        color: $black65;
      }
    }

    &.border {
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    }
  }

  .h-collapse-content {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 400;
    transition: 0.3s;
    overflow: hidden;
  }

  .h-collapse-content-show {
    padding: 0 16px;
    height: 0;
  }
}

.h-blue-title {
  width: 100%;
  margin-bottom: 8px;
  font-size: $font-m;
  font-weight: 500;
  color: $highLight-color;
}

.h-descriptions {
  width: 100%;
  margin-bottom: 20px;

  &-title {
    @extend .h-blue-title;
  }
}

//样式组件
.h-content-box {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .h-content-right {
    &-map {
      position: relative;
      width: 100%;
      height: 313px;
      margin-bottom: 16px;
      background: #000;
    }
  }


  .h-content-left {
    padding: 0 8px 0 0;
  }

  .h-content-left,
  .h-content-right {
    height: calc(100vh - 78px);
    overflow-y: auto;
    overflow-x: hidden;

    //&::-webkit-scrollbar {
    //  /*滚动条整体样式*/
    //  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    //  height: 1px;
    //}
    //
    //&::-webkit-scrollbar-thumb {
    //  /*滚动条里面小方块*/
    //  border-radius: 6px;
    //  background: #ddd;
    //}
    //
    //&::-webkit-scrollbar-track {
    //  /*滚动条里面轨道*/
    //  border-radius: 6px;
    //  background: #eee;
    //}
  }
}

//文本
.h-text-box {
  width: 100%;
  text-indent: 24px;
  font-size: 12px;
  font-weight: 400;
  color: rgba($color: #000000, $alpha: 0.25);
}

$h-checkbox-size: 13px;
.h-checkbox {
  display: inline-block;
  margin: 2px;
  width: $h-checkbox-size;
  height: $h-checkbox-size;
  border: 1px solid rgba(0, 0, 0, 0.15); //todo
  cursor: pointer;
}

.h-checkbox-inner,
.h-checkbox-checked {
  position: relative;
  background: #3b60a5; //todo
  border: 1px solid #3b60a5; //todo
}

.h-checkbox-inner {
  &::after {
    position: absolute;
    display: inline-block;
    top: -1px;
    width: 8px;
    height: 5px;
    border-bottom: 2px solid #fff;
    transform: rotate(0) scale(1) translate(20%, 50%);
    content: "";
  }
}

.h-checkbox-checked {
  &::after {
    position: absolute;
    display: table;
    width: 7px;
    height: 5px;
    border: 2px solid #fff;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg) scale(1) translate(-5%, 60%);
    content: "";
  }
}

.h-content-collapse {
  position: relative;
  width: 100%;
  padding-top: 30px;
  margin-bottom: 16px;

  &-header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 30px;
    padding: 8px;
    background: #f5f5f5;
    box-shadow: 0px 2px 4px 0px $black10;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      font-size: $size12;
      font-weight: 400;
      color: $black85;

      .h-icon {
        width: $size14;
        height: $size14;
        line-height: $size14;
        font-size: $size14;
        margin-right: 8px;
        transition: 0.3s;
      }

      .icon-spin {
        transform: rotate(-90deg);
      }
    }

    .header-right {
      display: flex;
      align-items: center;
    }
  }

  &-content {
    padding: 16px;
    background: $white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    overflow: hidden;
  }

  &-content-show {
    padding: 0 16px;
    height: 0 !important;
  }
}

.h-btn {
  padding: 0 14px;
  height: 24px;
  font-size: $font-s;
  color: $highLight-color;
  background: $white;
  border-radius: 2px;
  border: 1px solid $highLight-color;

}

.h-data-overview {
  width: 100%;

  &-title {
    font-size: $size14;
    font-weight: 500;
    color: $black85;
  }
}

.h-single-box {
  display: inline-flex;
  align-items: center;
  width: 100%;
  background: #fbfbfb;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  + .h-single-box {
    margin-left: 16px;
  }

  & > div {
    height: 20px;
    color: $black45;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .h-icon {
      margin-right: 4px;
      width: $size12;
      height: $size12;
      font-size: $size12;
      line-height: $size14;

      &.active {
        color: rgba(53, 198, 79, 1);
      }
    }
  }

  .h-single-active {
    position: relative;
    background: #eef8ed;
    border-radius: 4px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 1px;
      height: 14px;
      background: #35c64f;
    }
  }
}

.h-data-overview {
  &-title {
    font-size: $font-m;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  &-count {
    margin-bottom: 8px;
    font-size: $font-s;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    text-align: right;
  }

  &-item {
    margin-bottom: 16px;
    font-size: $font-s;

    + .h-data-overview-item {
      .h-data-overview-value {
        background: rgba(90, 157, 216, 0.85);
      }
    }
  }


  &-label {
    margin-right: 8px;
    width: 24px;
    height: 18px;
    line-height: 18px;
    font-size: $font-s;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }

  &-value {
    flex: auto;
    padding-left: 8px;
    color: $white;
    background: rgba(249, 118, 91, 0.85);
  }
}

// 列表数据tab
.h-data-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  .h-bar-width {
    width: 100%;

    > div + div {
      margin-left: 16px;
    }
  }
}

//头像
.h-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  font-size: 18px;
  font-weight: inherit;
  color: $white;
  border-radius: 50%;

  .small {
    width: 20px;
    height: 20px;
  }
}

// 分组
.h-group {
  display: inline-flex;
  line-height: 20px;
  color: $black45;
  background: #FBFBFB;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  cursor: pointer;

  &-item {
    padding: 0 12px;

    &.active {
      background: #EEF8ED;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
  }
}

// 必填 错误提醒框
.h-item-has-error {
  input, .ant-select-selector, .h-upload-thumbnail, .ant-input-number {
    border-color: #ff4d4f !important;
  }
}

// 地区选择器 第五列
.ant-cascader-menus {
  .ant-cascader-menu {
    &:nth-child(5) {
      display: none;
    }
  }
}

.h-tabs{
  width: 100%;
  height: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &>div{
    height: 24px;
    background: #fcfcfc;
    font-size: 12px;
    font-weight: 100;
    color: rgba(29,29,29,.65);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .active_tab{
    color: #3b60a5;
    background: #f2f8fd;
    box-shadow: 0 1px 2px 0 #dbdbdb80;
  }
}