.h-timeline-box {
  position: relative;
  height: 200px;
  overflow: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba($color: #eee, $alpha: 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($color: #eee, $alpha: 1);
  }
  .milestone-child {
    width: 92%;
    position: relative;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    .line {
      position: absolute;
      top: 44px;
      left: 0;
      border: 1px dashed #979797;
      content: "";
    }

    .green-line {
      position: absolute;
      top: 42px;
      left: 0;
      //border: 2px solid #02d281;
      border-top: 2px solid #02d281;
      border-bottom: 2px solid #02d281;
      content: "";
    }

    .child-content {
      position: relative;
      //width: 100px;
      height: 80px;
      // margin: 0 4px 0;
      display: flex;
      flex-flow: column;
      align-items: center;
      z-index: 2;

      &:last-child {
        margin-right: 0;
      }

      &::after,
      &::before {
        position: absolute;
        content: "";
      }

      &::before {
        left: 50%;
        top: 21px;
        height: 22px;
        border-left: 1px dashed #6d7278;
      }

      &::after {
        top: 42px;
        left: 48.6%;
        width: 6px;
        height: 6px;
        background: #6d7278;
        border-radius: 50%;
      }

      .content-name,
      .content-time {
        font-size: 12px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.25);
        text-align: center;
        //border-top: 1px solid #02d281;
      }

      .content-name {
        padding: 0 4px;
        margin-bottom: 30px;
        max-width: 200px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.45);
        background: #fbfbfb;
        box-shadow: 0 1px 2px 0 rgba(218, 218, 218, 0.5);
        border-radius: 2px;
      }

      .active-name {
        background: #e0ffc5;
        box-shadow: 0 1px 2px 0 #dadada;
        border-radius: 2px;
        border: none;
      }

      .content-rangeDate {
        position: absolute;
        top: 27px;
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        font-family: "OPPOSans-M";
        font-weight: normal;
      }
    }

    .red {
      &::after {
        background: #ff4a4a;
      }

      &::before {
        border-left: 1px solid #ff4a4a;
      }

      .content-rangeDate {
        left: 60%;
        color: #ff4a4a;
      }
    }

    .green {
      &::after {
        background: #02d281;
      }

      &::before {
        border-left: 1px solid #02d281;
      }

      .content-rangeDate {
        left: 36%;
        color: #02d281;
      }
    }
  }
  .mirroring {
    transform: rotateY(180deg);
    .child-content {
      transform: rotateY(180deg);
    }
  }
  .arc-line {
    position: absolute;
    top: 44px;
    right: 1px;
    width: 8%;
    height: 82px;
    border: 2px dashed #979797;
    border-left: 0;
    border-radius: 0 80px 80px 0;
  }
  .active-arc-line {
    top: 42px;
    width: 8%;
    height: 84px;
    border-top: 4px solid #02d281;
    border-right: 4px solid #02d281;
    border-bottom: 4px solid #02d281;
    //border: 4px solid rgba($color: #02d281, $alpha: 1);
    border-left: 0;
  }
}

.h-timeline-chart {
  height: 200px;
  overflow-y: auto;

  #timeline-chart {
    height: 200px;
  }
}
