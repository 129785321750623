$size12: 12px;
$size14: 14px;
$size16: 16px;

$white: #ffffff;
$font-s: $size12;
$font-m: $size14;
$font-l: $size16;

$black10: rgba(0, 0, 0, 0.10);
$black16: rgba(0, 0, 0, 0.16);
$black20: rgba(0, 0, 0, 0.20);
$black25: rgba(0, 0, 0, 0.25);
$black30: rgba(0, 0, 0, 0.30);
$black45: rgba(0, 0, 0, 0.45);
$black55: rgba(0, 0, 0, 0.55);
$black65: rgba(0, 0, 0, 0.65);
$black85: rgba(0, 0, 0, 0.85);

$highLight-color: #3b60a5;
$highLight-bg: #f0f7fd;
$mkl-green: #EEF8ED; //马卡龙绿
$mkl-blue: #f0f7fd; //马卡龙蓝
$fb-white: #FBFBFB; //一种白色

//menu
$h-header-box-shadow-color: rgba(156, 156, 156, 0.2);
$h-header-menu-active-color: $highLight-color;
$h-header-menu-bg: $highLight-bg;
$h-header-menu-border-color: $highLight-color;


//icon
$h-icon-color: $black45;
$h-icon-active-color: $highLight-color;

//login
$h-login-input-color: #999999;
$h-login-input-bg-color: #261818;
$h-login-background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(238, 238, 238, 0) 47%, rgba(204, 204, 204, 0.85) 100%);
$h-login-form-box-shadow-color: rgba(18, 18, 18, 0.2);
$h-login-form-background: rgba(18, 18, 18, 0.5);
$h-login-form-border-color: rgba(18, 18, 18, 0.6);

//search
$h-search-input-bg: #f1f1f1;

//h-table-line
$h-table-line-title-color: $black55;
$h-table-line-text-color: $black30;
$h-table-line-box-shadow-color: $black10;
$h-table-line-del-bg: rgba(243, 96, 74, 1);
$h-table-line-del-cancel-bg: rgba(209, 209, 209, 1);
$h-table-line-active-bg: $highLight-bg;
$h-table-line-active-shadow-color: $black16;

//h-drawer
$h-drawer-box-shadow-color: $black20;
$h-drawer-title-box-shadow-color: $black10;
$h-drawer-title-color: $black85;

// h-infoLine
$h-info-line-shadow-color: rgba(218, 218, 218, 0.5);
$h-info-line-title-bg: #F5F5F5;
$h-info-line-title-color: hsla(240, 1%, 37%, 0.8);
$h-info-line-value-color: $black45;
$h-info-line-flag-color: #35C64F;
$h-info-line-click-bg: $fb-white;
//$h-info-line-suffix-bg:#F0F7FD;

// h-input
$h-input-height: 100%;
$h-input-label-color: $black85;
$h-input-text-color: $black65;

// h-select
$h-select-tag-bg: #f5f5f5;

// upload
$h-upload-thumbnail-border-color: #d9d9d9;
$h-upload-thumbnail-bg: rgba(0, 0, 0, 0.02);