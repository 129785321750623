.h-home-box {
  width: 100%;
  height: 100vh;
  .h-home-content {
    width: 100%;
    height: calc(100vh - 24px);
    padding: 16px 24px 0;
    background: #fcfcfc;
    overflow-y: auto;
  }
}
