.upload-box {
  padding: 16px 0;
  &__head {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    & > span {
      margin-right: 24px;
    }
  }
  &__list {
    max-height: 600px;
    overflow: hidden auto;
    padding: 2px;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 6px;
      background: #ddd;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 6px;
      background: #eee;
    }
  }
  &__children1,
  &__children2 {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    &:last-child {
      margin-bottom: 0;
    }
    .children__head {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .file-type {
        width: 54px;
        height: 20px;
        line-height: 18px;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
      }
      .doc,
      .docx {
        background: rgba(0, 145, 255, 0.1);
        border: 1px solid #0091ff;
        color: #0091ff;
      }
      .xls,
      .xlsx {
        background: rgba(44, 171, 70, 0.1);
        border: 1px solid #2cab46;
        color: #2cab46;
      }
      .pdf {
        background: rgba(215, 56, 56, 0.1);
        border: 1px solid #d73838;
        color: #d73838;
      }
      .pptx {
        background: rgba(209, 120, 61, 0.1);
        border: 1px solid #d1783d;
        color: #d1783d;
      }
      .jpg,
      .jpeg,
      .png,
      .svg,
      .tif {
        background: rgba(8, 114, 102, 0.1);
        border: 1px solid #087266;
        color: #087266;
      }
      .a3x,
      .mwd,
      .rvt,
      .dae,
      .ifc {
        background: rgba(162, 53, 58, 0.1);
        border: 1px solid #a2353a;
        color: #a2353a;
      }
      .mp4,
      .mkv,
      .mov {
        background: rgba(71, 124, 166, 0.1);
        border: 1px solid #477ca6;
        color: #477ca6;
      }
      .dwg,
      .dxf {
        background: rgba(76, 86, 255, 0.1);
        border: 1px solid #4c56ff;
        color: #4c56ff;
      }
      .select-type {
        position: relative;
        width: 70px;
        height: 20px;
        .file-type {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .terrain {
            background: rgba(162, 53, 58, 0.1);
            border: 1px solid #a2353a;
            color: #a2353a;
          }
          .video {
            background: rgba(71, 124, 166, 0.1);
            border: 1px solid #477ca6;
            color: #477ca6;
          }
          .other {
            background: rgba($color: #666666, $alpha: 0.1);
            border: 1px solid #666;
            color: #666;
          }
          .model {
            background: rgba(76, 86, 255, 0.1);
            border: 1px solid #4c56ff;
            color: #4c56ff;
          }
          & > div {
            &:nth-child(1) {
              width: 54px;
              height: 20px;
              line-height: 18px;
              border-radius: 2px;
              font-size: 12px;
              font-weight: 400;
              text-align: center;
            }
            &:nth-child(2) {
              width: 0;
              height: 0;
              border-width: 4px;
              border-style: solid dashed dashed dashed;
              border-color: rgba(0, 0, 0, 0.25) transparent transparent
                transparent;
            }
          }
        }
        .select-list {
          position: absolute;
          left: 0;
          bottom: -108px;
          width: 64px;
          min-height: 86px;
          background: #fff;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          z-index: 8;
          & > div {
            padding: 4px;
            text-align: center;
            cursor: pointer;
            &:hover {
              background: #f0f7fd;
            }
          }
        }
      }
    }
  }
  &__children2 {
    .aaa {
      grid-template-columns: 142px 50px;
      grid-column-gap: 0;
    }
  }
  .ant-upload-list {
    width: 0;
    height: 0;
    overflow: hidden auto;
  }
}
