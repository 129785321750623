@import "../../base";

@font-face {
  font-family: "FZSKJW-GB1-0";
  src: url("../../assets/font/FZSKJW GB1 0.TTF");
}

@font-face {
  font-family: "OPPOSans-R";
  src: url("../../assets/font/OPPOSans R.ttf");
}

@font-face {
  font-family: "OPPOSans-M";
  src: url("../../assets/font/OPPOSans M.ttf");
}

.h-leadership {
  width: 100%;
  height: 100vh;
  background: rgba($color: #121212, $alpha: 0.8);

  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 4px;
    background: #5F6672;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 4px;
    background: #2D333F;
  }

  .radial-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 15, 29, 0.85) 0%, rgba(0, 15, 29, 0.2) 17%, rgba(0, 15, 29, 0) 51%, rgba(0, 15, 29, 0) 84%, rgba(0, 15, 29, 0.6) 100%);
    pointer-events: none;
    z-index: 2;
  }

  &-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 22px 30px;
    z-index: 9999;

    .top-title {
      display: flex;
      align-items: center;

      .h-icon,
      .title-logo {
        //width: 62px;
        height: 62px;
        line-height: 62px;
        font-size: 62px;
        color: #fff;
        margin-right: 10px;
      }

      .title {
        height: 34px;
        line-height: 34px;
        margin-bottom: 8px;
        font-size: 34px;
        font-family: "FZSKJW-GB1-0";
        font-weight: normal;
        color: #fff;
        letter-spacing: 2px;
      }

      .entitle {
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        font-family: "OPPOSans-R";
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.5);
      }
    }
  }

  &-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 36px;
    padding: 0 10px;
    background: rgba($color: #121212, $alpha: 0.8);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;

    .bottom-left,
    .bottom-right {
      display: flex;
      align-items: center;
    }

    .bottom-left {
      & > div {
        width: 76px;
        height: 22px;
        margin: 0 1px;
        line-height: 22px;
        border-radius: 2px;
        background: rgba($color: #fff, $alpha: 0.04);
        font-size: 14px;
        font-family: "OPPOSans-R";
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.6);
        text-align: center;
        cursor: pointer;
      }

      .button-active {
        background: #3b60a5;
      }
    }

    .bottom-right {
      .ant-divider {
        border-left: 1px solid rgba(255, 255, 255, 0.06);
      }

      .right-button {
        display: flex;

        & > div {
          width: 28px;
          height: 28px;
          border-radius: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .h-icon {
            color: rgba($color: #fff, $alpha: 0.9);
          }
        }

        .active {
          background: rgba($color: #fff, $alpha: 0.12);
        }
      }
    }
  }

  .ant-drawer-content-wrapper {
    box-shadow: none !important;

    .ant-drawer-content {
      background-color: rgba($color: #fff, $alpha: 0);
    }
  }

  .ant-drawer-left {
    .ant-drawer-body {
      padding: vh(170) 0 0 40px;
    }
  }

  .ant-drawer-right {
    .ant-drawer-body {
      padding: vh(170) 40px 0 0;
    }
  }

  .content-block {
    padding: 14px;
    max-height: vh(850);
    background: rgba(18, 23, 34, 0.82);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    border: 1px solid #000000;
    overflow-y: auto;
  }

  .content-card {
    & + .content-card {
      margin-top: vh(30);
    }


    .content-card-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .cn-title {
        height: 24px;
        line-height: 24px;
        margin-bottom: 8px;
        font-size: 24px;
        font-family: "OPPOSans-M";
        font-weight: normal;
        color: #fff;
      }

      .eng-title {
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        font-family: "OPPOSans-R";
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.5);
      }

      .content-card-suffix {
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-family: "OPPOSans-R";
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.6);

        span {
          height: 20px;
          line-height: 20px;
          font-size: 20px;
          font-family: "OPPOSans-R";
          font-weight: normal;
          color: rgba($color: #fff, $alpha: 0.8);
        }
      }

      .skin-link {
        width: 85px;
        height: 20px;
        background: rgba(2, 210, 129, 0.15);
        border-radius: 2px;
        padding: 0 4px;
        border: 1px solid #02d281;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 14px;
          font-family: "OPPOSans-R";
          font-weight: normal;
          color: #fff;
        }

        .h-icon {
          color: #fff;
        }
      }
    }

    .project-text-box {
      width: 100%;

      .project-title {
        height: 16px;
        line-height: 16px;
        margin-bottom: 6px;
        font-size: 16px;
        font-family: "OPPOSans-M";
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.8);
        text-overflow: ellipsis;
        -webkit-line-clamp:1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .project-overview {
        text-indent: 28px;
        font-size: 14px;
        font-family: "OPPOSans-R";
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.6);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; // 在第几行显示
        -webkit-box-orient: vertical;
      }

      .image-show-off {
        margin-top: 6px;

        .style1 {
          position: relative;
          //width: 380px;
          height: 104px;
          overflow: hidden;

          & > div {
            position: absolute;
            left: 0;
            top: 0;
            transition: 1s;
            height: 104px;
            display: flex;
            z-index: 1;

            .image-child {
              width: 186px;
              height: 104px;
              border: 4px solid #8c8c8c;
              margin-left: 8px;
              overflow: hidden;

              &:first-child {
                margin-left: 0;
              }

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .mobile-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
            font-size: 24px;
            z-index: 2;
            cursor: pointer;
          }

          .left {
            left: 10px;
          }

          .right {
            right: 10px;
          }

          .unActive {
            color: rgba($color: #fff, $alpha: 0.2);
          }
        }

        .style2 {
          //width: 380px;
          height: 212px;
          border: 4px solid #8c8c8c;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .milestone-box {
      position: relative;
      height: 145px;

      &::-webkit-scrollbar {
        height: 3px;
      }

      &::-webkit-scrollbar-track {
        background: rgba($color: #fff, $alpha: 0.4);
      }

      &::-webkit-scrollbar-thumb {
        background: rgba($color: #fff, $alpha: 0.6);
      }

      .milestone-child {
        width: 89%;
        position: relative;
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;

        .line {
          position: absolute;
          top: 42px;
          left: 0;
          border: 1px dashed #979797;
          content: "";
        }

        .green-line {
          position: absolute;
          top: 42px;
          left: 0;
          border: 2px solid rgba($color: #02d281, $alpha: 1);
          content: "";
        }

        .child-content {
          position: relative;
          //width: 100px;
          height: 80px;
          display: flex;
          flex-flow: column;
          align-items: center;
          z-index: 3;

          &:last-child {
            margin-right: 0;
          }

          &::after,
          &::before {
            position: absolute;
            content: "";
          }

          &::before {
            left: 50%;
            top: 22px;
            height: 24px;
            border-left: 1px dashed #6d7278;
          }

          &::after {
            top: 41px;
            left: 48%;
            width: 6px;
            height: 6px;
            background: #6d7278;
            border-radius: 50%;
          }

          .content-name,
          .content-time {
            font-size: 12px;
            font-family: "OPPOSans-R";
            font-weight: normal;
            color: rgba($color: #fff, $alpha: 0.8);
            text-align: center;
            transform: scale(0.8);
          }

          .content-name {
            max-width: 200px;
            height: 24px;
            padding: 0 4px;
            margin-bottom: 27px;
            line-height: 24px;
            background: rgba($color: #121722, $alpha: 0.62);
            border-radius: 2px;
            border: 1px solid #6d7278;
            font-size: 14px;
          }

          .active-name {
            background: rgba(2, 210, 129, 0.15);
            border-radius: 2px;
            border: 1px solid #02d281;
          }

          .content-rangeDate {
            position: absolute;
            top: 27px;
            height: 12px;
            line-height: 12px;
            font-size: 12px;
            font-family: "OPPOSans-M";
            font-weight: normal;
          }
        }

        .red {
          &::after {
            background: #ff4a4a;
          }

          &::before {
            border-left: 1px solid #ff4a4a;
          }

          .content-rangeDate {
            left: 60%;
            color: #ff4a4a;
          }
        }

        .green {
          &::after {
            background: #02d281;
          }

          &::before {
            border-left: 1px solid #02d281;
          }

          .content-rangeDate {
            left: 28%;
            color: #02d281;
          }
        }
      }

      .mirroring {
        transform: rotateY(180deg);

        .child-content {
          transform: rotateY(180deg);
        }
      }

      .arc-line {
        position: absolute;
        top: 42px;
        right: 0px;
        width: 10%;
        height: 82px;
        border: 2px dashed #979797;
        border-left: 0;
        border-radius: 0 80px 80px 0;
      }

      .active-arc-line {
        width: 11%;
        height: 84px;
        border: 4px solid rgba($color: #02d281, $alpha: 1);
        border-left: 0;
      }
    }

    .participating-box {
      width: 100%;
      //height: 185px;
      //overflow-y: auto;
      //overflow-x: hidden;

      .content-box {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1px;
        background: #1D222C;

        & > div {
          font-size: 14px;
          font-family: "OPPOSans-R";
          font-weight: normal;
          color: #fff;

          &:nth-child(1) {
            width: 76px;
            height: 30px;
            line-height: 30px;
            text-align: center;
          }

          &:nth-child(2) {
            width: 303px;
            height: 30px;
            padding: 0 10px;
            line-height: 30px;
            color: rgba($color: #fff, $alpha: 0.8);
            text-align: right;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  .left-list {
    width: 100%;
    max-height: vh(680);
    overflow-x: hidden;
    overflow-y: auto;

    .list-active {
      background: #02d281;

      .text {
        color: #000;
      }

      .h-icon {
        color: #000;
      }
    }

    & > div {
      //width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1px;
      padding: 0 12px 0 10px;
      height: 30px;
      background: #1D222C;
      cursor: pointer;

      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25),
        0 4px 15px 0 rgba(0, 0, 0, 0.35);
      }

      .text {
        width: 336px;
        font-size: 14px;
        font-family: "OPPOSans-R";
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.8);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .h-icon {
        color: rgba($color: #fff, $alpha: 0.6);
      }
    }
  }
}
