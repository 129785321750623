
.log-table {
  padding: 0;

  .ant-tabs {
    .ant-tabs-nav {
      .ant-tabs-tab {
        padding: 4px 0;
      }

      &::before {
        border-bottom: none;
      }

      .ant-tabs-ink-bar {
        height: 2px;
      }
    }

    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  .ant-tabs-content-holder {
    margin: 1px;
    padding-left: 1px;
  }

  .bg {
    height: 100%;
    padding: 20px 24px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.09);
  }

  .search-bar {
    display: flex;
    margin-bottom: 16px;

    .ant-picker-range {
      width: 290px;
      height: 24px;
      padding-left: 10px;
    }

    > * + * {
      margin-left: 16px;

      .ant-select-selector {
        height: 24px !important;

        .ant-select-selection-placeholder {
          padding-left: 10px;
          line-height: 22px !important;
        }

        .ant-select-selection-item {
          padding-left: 10px;
          line-height: 22px !important;
        }
      }
    }
  }

  .table {
    .ant-table {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
