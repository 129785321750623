@import "../../../color.scss";

.h-header {
  width: 100%;
  height: 24px;
  padding: 0 8px;
  background: #fff;
  box-shadow: 4px 0px 7px 0px rgba(156, 156, 156, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .h-icon {
    color: rgba(102, 102, 102, 1)
  }

  &-left {
    position: relative;
  }

  &-right {
    display: flex;
    align-items: center;
    height: inherit;
  }

  &-popover {
    position: absolute;
    top: 22px;
    right: 3px;
    padding: 8px;
    width: 104px;
    font-size: 12px;
    font-weight: 400;
    color: $black65;
    line-height: 18px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    z-index: 9;

    .user-name {
      margin-bottom: 8px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
      cursor: default;

      .en-name {
        height: 18px;
        color: rgba(0, 0, 0, 0.25);
        line-height: 18px;
      }
    }

    .logout {
      cursor: pointer;
      .h-icon {
        font-size: 12px;
      }
    }
  }
}
