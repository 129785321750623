@import "../../color";

.h-input,
.h-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;

  .required::before {
    position: absolute;
    left: -9px;
    top: 0;
    display: inline-block;
    content: "*";
    line-height: 1;
    font-size: 14px;
    font-family: SimSun;
    color: #f5222d;
  }

  &-text {
    position: relative;
    flex: none;
    margin-right: 8px;
    width: 48px;
    height: $h-input-height;
    line-height: $h-input-height;
    font-size: $font-s;
    font-weight: 300;
    color: $h-input-label-color;
  }


  &-input {
    width: 100%;
  }

  &-suffix {
    width: 100%;
    display: inline-grid;
    grid-template-columns: 186px 16px;
    grid-column-gap: 8px;
  }
}