.resources__list {
    width: 100%;
    min-height: 86px;
    display: flex;
    flex-wrap: wrap;

    &>div {
        width: 86px;
        height: 86px;
        margin: 0 14px 14px 0;
        border-radius: 14px;
        background: #F5F5F5;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;

        .play {
            width: 32px;
            height: 32px;
            background: rgba($color: #fff, $alpha: 0.8);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon-bofang {
                width: 11px;
            }
        }

    }
}

.text__box {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: rgba($color: #000000, $alpha: 0.3);
}