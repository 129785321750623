@import "../../color";

.h-login {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.h-login-text {
  position: fixed;
  bottom: 15px;
  left: calc(50% - 60px);
  color: #bbb;
  width: 130px;
  text-align: center;
  z-index: 2;

  &:hover {
    color: #000;
  }
}

.h-login-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(255, 255, 255, 0), rgba(238, 238, 238, 0), rgba(204, 204, 204, 0.85));
  z-index: 1;
}

.h-login-3dbg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.h-login-form {
  margin: auto 0;
  padding: 32px;
  width: 363px;
  height: 166px;
  background: $h-login-form-background;
  box-shadow: 0 0 37px 7px $h-login-form-box-shadow-color;
  border-radius: 4px;
  border: 1px solid $h-login-form-border-color;
  z-index: 2;


  input,
  .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-input-affix-wrapper>input.ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background: $h-login-input-bg-color;
    border-color: $h-login-input-color;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px transparent;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: $h-login-input-color !important;
    -webkit-box-shadow: 0 0 0 1000px $h-login-input-bg-color inset !important;
  }

  .h-icon {
    font-size: $font-l;
    color: $h-login-input-color;
  }

  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-form-item-control-input {
    height: 38px;
    min-height: 38px;
  }

  .ant-input {
    font-size: $font-m;
    color: $h-login-input-color;
  }
}