@import "../../color";

.h-upload {
  width: 100%;

  + .h-upload{
    margin-left: 7px;
  }

  &-thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    border-radius: 2px;
    border: 1px solid $h-upload-thumbnail-border-color;
    background: $h-upload-thumbnail-bg;
  }

  &-small {
    width: 60px;
    height: 60px;
    border-style: dashed;

    .h-icon {
      width: 26px;
      font-size: 26px;
    }
  }

  &-btn {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

}
