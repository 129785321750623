@import '../../color.scss';

.data__list {
    width: 100%;
    &__columns {
        width: 100%;
        display: flex;
        & > div {
            &:nth-child(1) {
                width: 18px;
            }
            &:nth-child(2) {
                width: 194px;
                margin-right: 4px;
                display: flex;
                align-items: center;
                & > div {
                    text-align: center;
                }
            }
        }
    }
    &__content {
        width: 100%;
        height: 551px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        overflow: hidden auto;
        .table__line {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            .table__checkbox {
                width: 13px;
                height: 13px;
                margin-right: 5px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                cursor: pointer;
            }
            .checkbox__active {
                position: relative;
                background: #3b60a5;
                border: none;
                &::after {
                    position: absolute;
                    display: table;
                    width: 5px;
                    height: 8px;
                    border: 2px solid #fff;
                    border-top: 0;
                    border-left: 0;
                    transform: rotate(45deg) scale(1) translate(82%, -20%);
                    opacity: 1;
                    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                    content: ' ';
                }
            }
            .table__content {
                width: 194px;
                margin-right: 4px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                & > div {
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
            .table__buttons {
                width: calc(100% - 214px);
                display: flex;
                & > .h-icon {
                    cursor: pointer;
                    &:nth-child(2) {
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}
.h-text-center {
    display: flex;
    justify-content: center;
}
.icon-color-red {
    color: #ff4545;
}
.icon-color-open {
    color: #02d281;
}
.icon-color-close {
    color: rgba(0, 0, 0, 0.3);
}

.h-line-edit-input {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    > span {
        width: 120px;
    }
    .ant-input,
    .ant-select,
    .ant-select-selector,
    .ant-select-selection-search,
    .ant-select-selection-search-input {
        flex: 1;
        height: $h-input-height !important;
    }
    .ant-select-selection-item {
        position: relative;
        top: -5px;
        height: 30px;
    }
}

.h-line-edit-btn-box {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    > button {
        background-color: #fff;
        border: 1px solid $highLight-color;
        color: $highLight-color;
        border-radius: 2px;
        padding: 1px 30px;
        outline: none;
        cursor: pointer;
    }
}
