
@media only screen and (width: 1440px) {
  .h-content-left, .h-menu {
    width: 888px;
  }
  .h-content-right {
    width: 488px;
  }
  .h-menu {
    width: 888px;

    .h-menu-list {
      width: calc(888px - 88px);
    }
  }
}

@media only screen and (max-width: 1439px) {
  .h-content-left {
    width: 63.7%;
  }
  .h-content-right {
    width: 35%;
  }
  .h-menu {
    width: 63.7%;

    //.h-menu-list {
    //  width: calc(63.7% - 88px);
    //}
  }
}

@media only screen and (min-width: 1441px) {
  .h-content-left {
    width: 63.7%;
  }
  .h-content-right {
    width: 35%;
  }
  .h-menu {
    width: 63.7%;

    //.h-menu-list {
    //  width: calc(63.7% - 88px);
    //}
  }
}