@import '../../color.scss';

.h-line-list-item {
    background-color: $h-info-line-title-bg;
    margin: 8px 0;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
}
.h-line-list-item-active {
    background: #f0f7fd;
    border-top: 2px solid #4a7af38c;
}
