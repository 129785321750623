.h-map-size {
  height: inherit;
}

.h-map-tools {
  position: absolute;
  right: 8px;
  top: 8px;

  .h-icon {
    color: #fff;
  }
}

.h-preview-img {
  //display: inline-flex;
  //align-items: center;
  //justify-content: center;
  width: 100%;
  height: 313px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  text-align: center;

  img {
    width: auto;
    max-height: 310px;
  }
}

.sediment__left__head {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
}

.upload-button {
  width: 212px;
  height: 32px;
  background: rgba($color: #000, $alpha: 0.02);
  border: 1px dotted rgba($color: #000000, $alpha: 0.15);
  border-radius: 2px;
  color: rgba($color: #000000, $alpha: 0.45);
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    &:last-child {
      margin-left: 11px;
      font-size: 12px;
    }
  }
}