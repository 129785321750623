//@import "~antd/dist/antd.css";
@import "./media.scss";
@import "color";
@font-face {
  font-family: "OPPOSans-M";
  src: url("./assets/font/OPPOSans M.ttf");
}

.h-white {
  color: $white;
}

.h-active-icon {
  color: $h-icon-active-color;
}

html,
body {
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background: #ddd;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 6px;
    background: #eee;
  }
}

.h-divider-label {
  margin-bottom: 4px;
  font-weight: 400;
  color: $black85;
}

.h-text-center {
  text-align: center;
}

.h-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.h-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse-btn {
  padding: 16px 0;
  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  display: flex;
  justify-content: center;

  .ant-btn {
    height: 24px;
    line-height: 14px;
    padding: 4px 12px;
    border: 1px solid #3b60a5;
    font-size: 12px;
    color: #3b60a5;
  }
}

.amap-logo {
  display: none !important;
}

.amap-marker-label {
  background: none;
  border: none;
  font-size: 16px;
  font-family: "OPPOSans-M";
  font-weight: normal;
  color: #fff;
  text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.6);
}
