@import "../../color.scss";

.h-table-line {
  position: relative;
  margin: 10px 8px 12px;
  min-height: 52px;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }

  &-icon {
    margin-right: 4px;
    font-size: 13px;
    color: rgba(53, 198, 79, 1);
  }

  &-abnormal {
    margin-right: 4px;
    font-size: 13px;
    color: #FF4545;
  }

  .h-table-logo {
    position: absolute;
    top: 7px;
    left: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    z-index: 3;

    .anticon {
      font-size: 28px;
      color: #666666;
    }

    img {
      height: 37px;
      display: inline-block;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .h-table-top {
    position: relative;
    display: flex;

    &-content {
      width: 100%;
      height: 32px;
      padding: 0 8px 0 53px;
      background: #f5f5f5;
      box-shadow: 0px 2px 4px 0px $h-table-line-box-shadow-color;
      border-radius: 4px 4px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        font-size: $font-m;
        font-weight: 500;
        color: $h-table-line-title-color;
      }

      .limit-width {
        width: 206px;
        display: flex;
        justify-content: space-between;
      }

      &-right {
        font-size: $font-s;
        font-weight: 400;
        color: $h-table-line-text-color;
        max-width: 50%;
        line-height: 14px;
        text-align: right;

        >span+span {
          margin-left: 24px;
        }
      }
    }

    &-content-del {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 61px);
      z-index: 2;
      transition: 0.5s;
    }

    &-del {
      position: absolute;
      top: 0;
      right: 0;
      width: 69px;
      height: 32px;
      padding-left: 29px;
      background: $h-table-line-del-bg;
      color: #fff;
      font-size: $font-s;
      font-weight: 500;
      line-height: 32px;
      box-shadow: 0px 2px 4px 0px $h-table-line-box-shadow-color;
      border-radius: 4px 2px 4px 4px;
      cursor: pointer;
    }

    .active-table {
      background: #f0f7fd;
      border-top: 2px solid #4a7af38c; //todo
      //box-shadow: 0px 2px 4px 0px $h-table-line-active-shadow-color;
    }
  }

  .h-table-bottom {
    position: relative;
    display: flex;

    &-content {
      width: 100%;
      height: 20px;
      padding: 0 8px 0 53px;
      background: #fbfbfb;
      box-shadow: 0px 1px 2px 0px $h-table-line-box-shadow-color;
      border-radius: 0 0 4px 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        display: inline-block;
        width: 300px;
      }

      &-left,
      &-center {
        font-size: $font-s;
        font-weight: 400;
        color: $h-table-line-text-color;
      }
    }

    &-content-del {
      position: absolute;
      top: 32px;
      left: 0;
      width: calc(100% - 61px);
      z-index: 2;
      transition: 0.5s;
    }

    &-del {
      position: absolute;
      top: 32px;
      right: 0;
      width: 69px;
      height: 20px;
      padding-left: 29px;
      background: $h-table-line-del-cancel-bg;
      color: #fff;
      font-size: $font-s;
      font-weight: 500;
      line-height: 20px;
      box-shadow: 0 2px 4px 0 $h-table-line-box-shadow-color;
      border-radius: 4px;
      cursor: pointer;
    }

    .h-table-button-box {
      height: 20px;
      display: flex;
      align-items: center;

      //& > div {
      //  margin-left: 8px;
      //}
      .h-icon {
        cursor: pointer;
      }

      .icon-plus-circle {
        width: 14px;
        height: 14px;
        font-size: 14px;
        line-height: 14px;
      }

      .activeIcon {
        color: $h-icon-active-color;
      }
    }
  }
}